import React from 'react';
import './EmailSubscription.css';

function EmailSubscription() {
  return (
    <div className="email-subscription">
      <h1>Enter Our Wild Mindscape</h1>
      <p>Leave your email below to join the community.<br />Receive exclusive stories from the <strong>Reverie House Library</strong>.</p>
      <form method="post" action="https://sendfox.com/form/3zp5p6/m7yrzl" className="sendfox-form" id="m7yrzl" data-async="true" data-recaptcha="false">
        <p>
          <input type="email" id="sendfox_form_email" placeholder="Email" name="email" required />
        </p>
        {/* no botz please */}
        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
          <input type="text" name="a_password" tabIndex="-1" value="" autoComplete="off" />
        </div>
        <p><button type="submit">Join Now</button></p>
      </form>
      <script src="https://cdn.sendfox.com/js/form.js" charset="utf-8"></script>
    </div>
  );
}

export default EmailSubscription;
