import React, { useState, Suspense } from 'react';
import './Community.css';
import EmailSubscription from './EmailSubscription';
const Reader = React.lazy(() => import('./Reader'));  // Lazy load Reader component

function Community() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);

  const currentStory = {
    title: 'Ollie and Pecky',
    summary:
      "Ollie (a genial chef) and Pecky (a rather brilliant chicken) cross paths in our wild mindscape...",
    image: './images/squares/pecky.png',
    storyFile: './texts/ollie_and_pecky.md'
  };

  const toggleModal = () => {
    if (window.innerWidth > 600) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleRead = (storyFile) => {
    setSelectedStory(storyFile);
  };

  return (
    <main className="community-main">
      <section className="community-invitation">
        <EmailSubscription currentStory={currentStory} />

        <div className="story-highlight-box">
          <div className="dispatch-header">
            <h2>Latest Dispatch</h2>
            <p className="fine-print">October 2024</p>
          </div>
          <hr />
          <div className="story-highlight">
            <img src={currentStory.image} alt={currentStory.title} className="story-image" onClick={toggleModal} />
            <div className="story-textbox">
              <h3>{currentStory.title}</h3>
              <p>{currentStory.summary}</p>
              <button
                className="read-now-btn"
                onClick={() => handleRead(currentStory.storyFile)}
                style={{ marginTop: '10px' }}
              >
                Read Now
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="modal-overlay" onClick={toggleModal}>
            <div className="modal-content" tabIndex="-1">
              <img src={currentStory.image} alt={currentStory.title} className="modal-image" />
            </div>
          </div>
        )}
      </section>

      {selectedStory && (
        <Suspense fallback={<div>Loading story...</div>}>
          <Reader storyFile={selectedStory} title={currentStory.title} onClose={() => setSelectedStory(null)} />
        </Suspense>
      )}
    </main>
  );
}

export default Community;
