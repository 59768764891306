import React from 'react';
import './Welcome.css';

function Welcome() {
  return (
    <main className="welcome-main">
      <section className="welcome-container">
        <h1>Welcome to Reverie House!</h1>
        <p>Thanks for joining the community, we're glad you're here.</p>
        <p>Stay tuned to your inbox for updates, and enjoy the Reverie House Library!</p>
        <button className="explore-button" onClick={() => window.location.href = '/library'}>
          Explore the Library
        </button>
      </section>
    </main>
  );
}

export default Welcome;
