import React, { useState, useEffect, Suspense } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Home.css';

// Lazy load the splash components
const JoinSplash = React.lazy(() => import('./splash/JoinSplash'));
const SeekerSplash = React.lazy(() => import('./splash/SeekerSplash'));

function Home() {
  const splashes = [
    SeekerSplash,
    JoinSplash
  ];

  const [currentSplash, setCurrentSplash] = useState(0);

  const handleNext = () => {
    setCurrentSplash((prev) => (prev + 1) % splashes.length);
  };

  const handlePrev = () => {
    setCurrentSplash((prev) => (prev - 1 + splashes.length) % splashes.length);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleNext();
      } else if (event.key === 'ArrowLeft') {
        handlePrev();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true
  });

  const CurrentSplash = splashes[currentSplash];

  return (
    <main className="widget" {...swipeHandlers}>
      <button className="nav-button left" onClick={handlePrev}>&lt;</button>
      <div className="carousel">
        {/* Wrap splash component in Suspense with fallback */}
        <Suspense fallback={<div>Loading...</div>}>
          <CurrentSplash />
        </Suspense>
      </div>
      <button className="nav-button right" onClick={handleNext}>&gt;</button>

      <div className="mobile-nav">
        <button className="mobile-nav-button" onClick={handlePrev}>&lt; Previous</button>
        <button className="mobile-nav-button" onClick={handleNext}>Next &gt;</button>
      </div>
    </main>
  );
}

export default Home;
