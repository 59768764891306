
import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={{ backgroundColor: '#333', color: 'white', textAlign: 'center', padding: '1em 0', fontFamily: 'Lato, sans-serif' }}>
      <p style={{ margin: '0.5em 0' }}>
        <a href="mailto:books@reverie.house" style={{ color: 'white', textDecoration: 'none', transition: 'color 0.3s ease' }}>books@reverie.house</a>
      </p>
      <p style={{ margin: '0.5em 0' }}>© {currentYear} Reverie House Press</p>
    </footer>
  );
}

export default Footer;
