import React, { useState, useEffect } from 'react';
import './Library.css';
import storiesData from './Library.json';
import Reader from './Reader';

function Library() {
  const [filter, setFilter] = useState('All Stories');
  const [selectedStory, setSelectedStory] = useState(null);
  const [activeStoryIndex, setActiveStoryIndex] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);
  const [filteredStories, setFilteredStories] = useState(storiesData);

  useEffect(() => {
    const filtered = storiesData.filter(
      (story) => filter === 'All Stories' || story.category === filter
    );
    setFilteredStories(filtered);
    setActiveStoryIndex(null);
    setContentVisible(false);
  }, [filter]);

  const handleRead = (storyFile, title, buttons, color) => {
    setSelectedStory({ storyFile, title, buttons, color });
    setContentVisible(false);
  };

  const closeReader = () => {
    setSelectedStory(null);
    setContentVisible(false);
    setActiveStoryIndex(null);

    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  const handleCardClick = (index) => {
    if (activeStoryIndex === index) {
      setActiveStoryIndex(null);
      setContentVisible(false);
    } else {
      setActiveStoryIndex(index);
      setContentVisible(false);
    }
  };

  const handleTransitionEnd = (e, index) => {
    if (e.propertyName === 'opacity' && activeStoryIndex === index) {
      setContentVisible(true);
    }
  };

  return (
    <main className="library-main">
      <div className="library-widget">
        <nav className="library-nav">
          <button className={filter === 'All Stories' ? 'active' : ''} onClick={() => setFilter('All Stories')}>All Stories</button>
          <button className={filter === 'Novel' ? 'active' : ''} onClick={() => setFilter('Novel')}>Novels</button>
          <button className={filter === 'Short' ? 'active' : ''} onClick={() => setFilter('Short')}>Shorts</button>
        </nav>
        <div className="story-cards">
          {filteredStories.map((story, index) => (
            <div
              className={`story-card ${activeStoryIndex === index ? 'active' : ''}`}
              key={index}
              onClick={() => handleCardClick(index)}
              style={{ '--order': index }}
            >
              <div
                className="image-overlay"
                style={{ backgroundImage: `url(${story.image})` }}
                onTransitionEnd={(e) => handleTransitionEnd(e, index)}
              ></div>
              <div className={`content ${contentVisible ? 'visible' : ''}`}>
                <h3>{story.title}</h3>
                <p>{story.summary}</p>
                <div className="button-group">
                  {story.buttons.map((button, btnIndex) => (
                    <a key={btnIndex} href={button.link} target="_blank" rel="noopener noreferrer">
                      <button
                        className="read-now-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (story.storyFile) {
                            handleRead(story.storyFile, story.title, story.buttons, story.color);
                          }
                        }}
                        style={{
                          '--button-bg': story.color,
                          '--button-bg-dark': `hsl(${getHSLFromHex(story.color).h}, ${getHSLFromHex(story.color).s}%, ${getHSLFromHex(story.color).l - 10}%)`
                        }}
                      >
                        {button.text}
                      </button>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedStory && (
        <Reader
          storyFile={selectedStory.storyFile}
          title={selectedStory.title}
          buttons={selectedStory.buttons}
          onClose={closeReader}
          color={selectedStory.color}
        />
      )}
    </main>
  );

  // Function to convert HEX color to HSL
  function getHSLFromHex(hex) {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    r /= 255; g /= 255; b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s = 0, l = (max + min) / 2;
    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }
    s = s * 100;
    l = l * 100;
    return { h: Math.round(h * 360), s: Math.round(s), l: Math.round(l) };
  }
}

export default Library;
