import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('Home');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);

    // Set the active link based on the current path
    switch (location.pathname) {
      case '/author':
        setActiveLink('Author');
        break;
      case '/community':
        setActiveLink('Community');
        break;
      case '/library':
        setActiveLink('Library');
        break;
      default:
        setActiveLink('Home');
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [location]);

  const linkStyle = (page) => ({
    color: activeLink === page ? '#FFD700' : 'white', // Gold for active link
    margin: '0 10px',
    padding: '5px',
    fontSize: '18px',
    textDecoration: 'none',
    transition: 'color 0.3s ease' // Smooth transition for hover effect
  });

  return (
    <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1em', backgroundColor: '#333', color: 'white', fontFamily: 'sans-serif', minHeight: '60px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <nav style={{ display: 'flex', gap: '15px' }}>
        <Link to="/" style={linkStyle('Home')} onClick={() => setActiveLink('Home')}>Home</Link>
        <Link to="/author" style={linkStyle('Author')} onClick={() => setActiveLink('Author')}>Author</Link>
        <Link to="/community" style={linkStyle('Community')} onClick={() => setActiveLink('Community')}>Community</Link>
        <Link to="/library" style={linkStyle('Library')} onClick={() => setActiveLink('Library')}>Library</Link>
      </nav>
      <div style={{ flexShrink: 0, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', overflow: 'hidden' }}>
        {!isMobile && <h1 style={{ margin: 0, fontSize: '24px', fontFamily: 'sans-serif', whiteSpace: 'nowrap' }}>Welcome to Reverie House</h1>}
      </div>
    </header>
  );
}

export default Header;
