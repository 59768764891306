import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import './Reader.css';

function Reader({ storyFile, title, buttons = [], onClose, color }) {
  const [storyContent, setStoryContent] = useState('');
  const readerTextRef = useRef(null);

  useEffect(() => {
    fetch(storyFile)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((text) => setStoryContent(text))
      .catch((err) => console.error('Error loading the story:', err));
  }, [storyFile]);

  return (
    <div id="reader-component">
      <div className="reader-overlay">
        <div className="reader-content">
          <header className="reader-header" style={{ backgroundColor: color }}>
            <h1>{title}</h1>
            <button className="reader-close" onClick={onClose}>
              ×
            </button>
          </header>
          <div className="reader-text" ref={readerTextRef}>
            <ReactMarkdown>{storyContent}</ReactMarkdown>
          </div>
          <footer className="reader-footer">
            {buttons.map((button, index) => (
              <a key={index} href={button.link} target="_blank" rel="noopener noreferrer">
                <button className="footer-btn" style={{ background: color }}>
                  {button.text}
                </button>
              </a>
            ))}
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Reader;
