import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Author from './components/Author';
import Community from './components/Community';
import Library from './components/Library';
import Footer from './components/Footer';
import Welcome from './components/Welcome';

function App() {

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/author" element={<Author />} />
        <Route path="/community" element={<Community />} />
        <Route path="/library" element={<Library />} />
        <Route path="/welcome" element={<Welcome />} />
      </Routes>
      <Footer />


    </div>
  );
}

export default App;
