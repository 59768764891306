import React from 'react';
import './Author.css';

function Author() {
  return (
    <main className="author-widget">
      <section className="author-box">
        <p className="author-subtitle larger">Through odd visions at sleep, I have been to Reverie House.</p>
        <p className="author-description">
          Never by will have I traveled there, but within my sweetest dreams I have found the ways back to our wild mindscape. It is a precious place, beloved to me and the reason I write.
        </p>
        <p className="author-description">
          When the depths of the dreaming reality availed themselves, I took up my pen for the first time and became determined to record all I could. Today, my goal remains to bring many amazing stories of the people and happenings at Reverie House to you, in our waking world.
        </p>
        <p className="author-signature">— D.V. Errantson</p>
        <hr />
        <p className="finefont">The author has no social media presence and can only be reached through books@reverie.house</p>
      </section>
    </main>
  );
}

export default Author;
